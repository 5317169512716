.alert {
	&--error {
		border: 1px solid #ff3300 !important;
		border-bottom: 3px solid #ff3300 !important;
	}

	&--error-text {
		color: #c30000;
	}

	&--success {
		background-color: #4bb543;
		border-color: #4bb543;
	}

	&--info {
		background-color: #009aff;
		border-color: #009aff;
	}
}
