.c-icon {
	width: 1em;
	height: 1em;
	line-height: 1;
	display: block;

	svg {
		fill: currentColor;
		display: inline-block;
		width: inherit;
		height: inherit;
	}
}
span.c-icon {
	display: inline-block;
	vertical-align: middle;
}
