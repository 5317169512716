.c-pageheader {
	position: relative;
	height: 60px;

	&--backbutton {
		position: absolute;
		height: 60px;
		left: 0;
		.touchable {
			display: block;
			height: 100%;
			width: 100%;
			padding: 0 15px 0 50px;
		}
		.c-icon {
			position: absolute;
			left: 15px;
			top: 18px;
			svg {
				height: 24px;
				width: 24px;
			}
		}
	}

	&--header {
		text-align: center;
		padding: 14px 0;
		h3 {
			font-size: 16px;
		}
	}

	&--closebutton {
		position: absolute;
		right: 15px;
		top: 15px;
		button {
			width: 30px;
			white-space: nowrap;
			overflow: hidden;
		}
	}
}
