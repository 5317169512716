.c-productlist {
	> ul {
		list-style: none;
		padding: 0;
		scroll-snap-type: x mandatory;
		display: flex;
		overflow-x: scroll;
		-webkit-overflow-scrolling: touch;
		overflow-y: hidden;
		padding-bottom: 20px;
	}

	&--product {
		height: 44vh;
		min-width: 70vw;
		text-align: center;
		scroll-snap-align: start;
		position: relative;
		margin-right: 20px;
		border-radius: 4px;
		border: 1px solid #00ccde;
		padding: 20px;
		padding-bottom: 100px;

		.c-buttongroup {
			padding-top: 0;
		}

		h2 {
			font-size: 28px;
			margin: 0;
		}

		h3 {
			font-size: 18px;
		}

		p {
			font-size: 18px;
			margin-bottom: 0;

			@media (max-width: 320px) {
				margin-bottom: -7px;
			}
		}

		a {
			text-decoration: none;
			color: inherit;
		}
	}

	&--one {
		display: flex;
		justify-content: center;

		.c-productlist--product {
			margin-right: 0;
		}
	}

	&--qrcode {
		svg {
			height: 200px;
		}

		@media (max-width: 320px) {
			height: 188px;
		}
	}
}
