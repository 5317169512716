.radio-list {
	li {
		position: relative;
		margin-left: -1em;
		margin-right: -1em;
	}

	input {
		opacity: 0;
		position: absolute;

		&:checked + span {
			background-color: var(--color-blue);
			color: #fff;
			font-weight: bold;

			.icon {
				opacity: 1;
			}
		}
	}

	.icon {
		position: absolute;
		transition: all 0.3s;
		top: 50%;
		transform: translateY(-50%);
		right: 1em;
		opacity: 0.25;
	}

	label > span {
		display: block;
		font-size: 1.2em;
		padding: 1em 0.83em;
		border-bottom: 1px solid var(--color-light-gray);

		.sub {
			display: block;
		}
	}
}
