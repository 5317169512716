.toggle {
	position: relative;
	display: inline-block;
	width: 60px;
	height: 15px;

	input {
		opacity: 0;
		width: 0;
		height: 0;
	}

	.slider {
		position: absolute;
		cursor: pointer;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background-color: var(--color-gray);
		transition: 0.3s;
		border-radius: 34px;
		&:before {
			position: absolute;
			content: '';
			height: 26px;
			width: 26px;
			left: 0;
			bottom: -7px;
			background-color: white;
			transition: 0.3s;
			border-radius: 50%;
			border: 2px solid var(--color-gray);
		}
	}
}

input:checked + .slider {
	background-color: var(--color-blue);
}

input:focus + .slider {
	box-shadow: 0 0 1px var(--color-gray);
}

input:checked + .slider:before {
	transform: translateX(30px);
	border: 2px solid black;
}
