.c-arrow-link {
	display: block;
	padding: 1em;
	margin-bottom: -1px;
	border-bottom: 1px solid var(--color-light-gray);
	border-top: 1px solid var(--color-light-gray);
	display: flex;
	text-decoration: none;
	justify-content: space-between;
	&:first-of-type {
		border-top: none;
	}

	.container & {
		margin-left: -15px;
		margin-right: -15px;
	}

	&--pretitle {
		margin-bottom: 0.1em;
		font-size: 16px;
		font-weight: 300;
	}

	&--title {
		line-height: 1.15;
		font-size: 20px;
	}

	&--arrow {
		align-self: center;
		//opacity: 0.25;
		font-size: 1.25em;
	}
}
