.error-boundary {
	//color: #fff;
	height: 100vh;
	width: 100vw;
	//background-color: var(--color-red);
	padding: 2em;
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	justify-content: center;
	flex-wrap: wrap;

	&__title {
		font-size: 3em;
		flex: none;
		font-weight: bold;
		margin-bottom: 0.5em;
		line-height: 1;
	}

	&__description {
		font-size: 1.2em;
		flex: none;
		opacity: 0.75;
	}
}
