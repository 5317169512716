.offline-banner {
	padding: 0.75em 1em;
	background-color: var(--color-red);
	color: #fff;
	text-align: center;
	animation: offline-banner-in 0.4s both;
	z-index: 1;
	position: absolute;
	left: 0;
	right: 0;
	bottom: 100%;
}

@keyframes offline-banner-in {
	from {
		transform: translateY(1em);
		opacity: 0;
	}

	to {
		transform: translateY(0);
		opacity: 1;
	}
}
