body {
	--color-blue: #00cbdd;
	--color-cyan: #78e3ed;
	--color-purple: #6000d7;
	--color-red: #ff007b;
	--color-light-gray: #f6f7f8;
	--color-gray: #ccc;
	--color-dark-gray: #004763;
	--color-link: #0061cd;
}
