body,
html {
	width: 100%;
	height: 100%;
	overflow: hidden;
	box-sizing: border-box;
	position: fixed;
	top: 0;
	bottom: 0;
}

#sno-app {
	width: 100%;
	overflow-x: hidden;
	height: 100%;
}

* {
	scroll-behavior: smooth;
	border: none;
	margin: 0;
	padding: 0;
	font-size: inherit;
	color: inherit;
	list-style-type: none;
	font-family: "Nunito";
	padding: 0;
	line-height: inherit;
	touch-action: manipulation;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0) !important;
	outline: none;
	background-color: transparent;
}

a {
	text-decoration: none;
}

*:not(input):not(textarea) {
	// this disables input fields on touch!
	-webkit-user-select: none !important;
	-webkit-touch-callout: none !important;
}

::-webkit-search-cancel-button {
	display: none;
}

::placeholder {
	color: rgba(#000, 0.25);
}

.visually-hidden {
	position: absolute;
	overflow: hidden;
	clip: rect(0 0 0 0);
	height: 1px;
	width: 1px;
	margin: -1px;
	padding: 0;
	border: 0;
}

.hr {
	margin-top: 1.5em;
	margin-bottom: 1.5em;
	border-bottom: 1px solid var(--color-gray);
}
