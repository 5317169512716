.c-container-header {
	text-align: center;
	margin: 24px 0;

	h2 {
		color: var(--color-purple);
		font-size: 28px;
		font-weight: 600;
		margin: 0;
	}

	&--left {
		text-align: left;
	}
}
