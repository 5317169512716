.title-header {
	position: relative;
	padding: 5px;
	border-bottom: 1px solid #eee;

	&__left,
	&__right {
		position: absolute;
		top: 17px;
		width: 20px;
		//margin-bottom: 0.175em;
	}

	&__left {
		left: 10px;
	}

	&__title {
		font-size: 1.1em;
		font-weight: bold;
		flex: 1;
		text-align: center;
	}

	&__right {
		right: 10px;
	}
}
