.c-textblock {
	line-height: 1.4;
	font-size: 1.2em;
	font-family: "Nunito";

	&--centered {
		text-align: center;
	}

	h1 {
		line-height: 1.1;
		font-size: 40px;
		color: #6000d3;
	}

	h2 {
		line-height: 1.1;
		font-size: 32/16 + em;
	}

	h3 {
		line-height: 1.1;
		color: var(--color-purple);
		font-size: 24/16 + em;
	}

	h4 {
		line-height: 1.1;
		font-size: 18/16 + em;
	}

	h5 {
		line-height: 1.1;
		font-size: 1em;
	}

	h6 {
		line-height: 1.1;
		opacity: 0.5;
		font-size: 0.85em;
	}

	p,
	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		margin: 0.5em 0;

		&--first {
			margin-top: 0;
		}
		&--last {
			margin-bottom: 0;
		}
	}
}
