.page-summary {
	h2 {
		color: var(--color-purple);
		font-size: 20px;
		font-weight: normal;
	}

	.c-ordersummary {
		h3 {
			display: none;
		}

		&--ordertable {
			border-bottom: 2px solid var(--color-purple);
		}

		&--sum {
			color: var(--color-purple);

			td {
				p {
					float: right;
					margin-right: 40px;
				}
			}
		}
	}
}
