.c-button {
	display: inline-block;
	padding: 10px 24px;
	text-align: center;
	color: #fff;
	background-color: var(--color-purple);
	border-radius: 3em;
	font-size: 20px;
	line-height: 1.25;
	box-sizing: border-box;
	margin-right: 10px;
	position: relative;

	.c-icon {
		margin-left: 10px;
		width: 25px;
		height: 25px;
	}

	&:last-of-type {
		margin-right: 0;
	}

	&--secondary {
		background-color: white;
		color: black;
	}

	&--grey {
		background-color: #dbe3e8;
		color: black;
	}

	&--outlined {
		background-color: transparent;
		color: black;
		border: 2px solid #00ccde;
	}

	&--plain {
		background: transparent;
		border-radius: none;
		min-width: 0;
		color: inherit;
		padding: 0;
		font-size: inherit;
	}

	&--thin {
		padding: 8px;
		width: 176px;
		font-size: 16px;
	}

	&--link {
		background: transparent;
		border-radius: none;
		min-width: 0;
		color: inherit;
		padding: 0;
		font-size: inherit;
		text-decoration: underline;
		color: var(--color-link);
	}

	&--round {
		border: 1px solid #666;
		border-radius: 100%;
		background-color: transparent;
		min-width: 1em;
		width: unset;
		padding: 0.2em 0.4em;
		color: black;
		&:active {
			background-color: #00ccde;
		}
	}

	&--bordered {
		border: 2px solid #7511f1;
		background-color: transparent;
		color: inherit;
	}

	&--wide {
		width: 100%;
		margin-bottom: 10px;
		&:last-of-type {
			margin-bottom: 0;
		}
	}

	&--full {
		display: block;
		width: 100%;
		text-decoration: none;
	}

	&--square {
		border-radius: 8px;
		color: inherit;
	}

	&--loading {
		//opacity: 0.2;
		//border: 4px solid;
		cursor: not-allowed;
		position: relative;
		height: 50px;
	}

	&--disabled {
		opacity: 0.5;
		cursor: not-allowed;
	}

	&--loader {
		display: inline-block;
		position: relative;
		width: 64px;
		height: 10px;

		div {
			position: absolute;
			width: 11px;
			height: 11px;
			border-radius: 50%;
			background: #fff;
			animation-timing-function: cubic-bezier(0, 1, 1, 0);

			&:nth-child(1) {
				left: 6px;
				animation: loader-1 0.6s infinite;
			}

			&:nth-child(2) {
				left: 6px;
				animation: loader-2 0.6s infinite;
			}

			&:nth-child(3) {
				left: 26px;
				animation: loader-2 0.6s infinite;
			}

			&:nth-child(4) {
				left: 45px;
				animation: loader-3 0.6s infinite;
			}
		}
	}

	@keyframes loader-1 {
		0% {
			transform: scale(0);
		}
		100% {
			transform: scale(1);
		}
	}
	@keyframes loader-3 {
		0% {
			transform: scale(1);
		}
		100% {
			transform: scale(0);
		}
	}
	@keyframes loader-2 {
		0% {
			transform: translate(0, 0);
		}
		100% {
			transform: translate(19px, 0);
		}
	}

	&--selected {
		background-color: var(--color-blue);
		color: white;
		border: 2px solid transparent;
	}

	&--active {
		background-color: #78e3ed;
	}
}

@-webkit-keyframes load7 {
	0%,
	80%,
	100% {
		box-shadow: 0 2.5em 0 -1.3em;
	}
	40% {
		box-shadow: 0 2.5em 0 0;
	}
}
@keyframes load7 {
	0%,
	80%,
	100% {
		box-shadow: 0 2.5em 0 -1.3em;
	}
	40% {
		box-shadow: 0 2.5em 0 0;
	}
}
