.page-daypass {
	h2 {
		font-size: 20px;
		font-weight: normal;
	}
	.c-select-list {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		grid-gap: 8px;
		text-align: left;

		li {
			border-radius: 8px;
			border: 2px solid #dbe3e8;
			padding: 16px;

			a {
				display: block;
			}

			h3 {
				font-size: 18px;
				font-weight: 600;
				margin: 0;
			}
		}
	}
}
