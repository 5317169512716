.c-drawer {
	padding: 15px;
	background-color: var(--color-light-gray);
	box-shadow: 0 0 1em rgba(#000, 0.1);
	border-top: 1px solid #eee;

	&-enter {
		visibility: hidden;
		height: 0;
		max-height: 0;
	}

	&-enter-active {
		max-height: 0;
		height: 0;
		transition: max-height 0.5s ease-in-out;
	}

	&-enter-done {
		height: auto;
		max-height: 600px;
		overflow: hidden;
		transition: max-height 0.5s ease-in-out;
	}
	&-exit {
		max-height: 0;
		transition: max-height 0.5s ease-in-out;
	}
	&-exit-active {
		height: 0;
		max-height: 0;
		transition: max-height 0.5s ease-in-out;
	}

	&-exit-done {
		visibility: hidden;
	}
}
