.c-card {
	border: 1px solid #dbe3e8;
	padding: 16px;
	border-radius: 8px;

	&--active {
		border: 1px solid var(--color-blue);
		.c-icon {
			&--checkmark-heavy {
				background-color: var(--color-blue);
				color: white;
				border-radius: 50%;
				padding: 12px;
			}
		}
	}
}
