.c-checkbox {
	display: inline-block;
	position: relative;
	padding-left: 40px;
	margin-bottom: 20px;
	cursor: pointer;
	font-size: 16px;
	user-select: none;

	input {
		position: absolute;
		opacity: 0;
		cursor: pointer;
		height: 0;
		width: 0;
	}

	&--checkmark {
		position: absolute;
		top: 0;
		left: 0;
		width: 24px;
		height: 24px;
		background: rgb(255, 255, 255);
		border: 2px solid rgb(219, 227, 232);
		border-radius: 1px;

		&::after {
			content: "";
			position: absolute;
			display: none;
		}
	}

	input:checked ~ .c-checkbox--checkmark {
		background-color: #78e3ed;
		border: 2px solid #78e3ed;
		background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cscript xmlns=''/%3E%3Cpath fill='%23000000' d='M3.22271902,14.0526178 C2.87526988,13.6233193 2.94162194,12.9936409 3.37092048,12.6461917 C3.80021902,12.2987426 4.42989743,12.3650946 4.77734656,12.7943932 L8.05943283,16.8501409 C8.12202765,16.9315449 8.25517851,16.9956847 8.41319332,16.9997949 C8.57393683,17.0039761 8.71661959,16.9446377 8.80229643,16.8467432 L19.2596246,5.32784126 C19.6308513,4.91892949 20.2632785,4.88837974 20.6721903,5.2596065 C21.0811021,5.63083325 21.1116518,6.26326048 20.7404251,6.67217226 L10.2961792,18.1763759 C9.82201794,18.7197828 9.1038871,19.0184364 8.36118784,18.9991176 C7.61575988,18.9797279 6.91418093,18.641772 6.48938406,18.0888188 L3.22271902,14.0526178 Z'/%3E%3C/svg%3E");
		background-position: center;
	}

	input:checked ~ .c-checkbox--checkmark:after {
		display: inline-block;
	}
}
