.page-coupon-code {
	h2 {
		font-weight: normal;
		font-size: 20px;
	}

	form {
		display: flex;
		flex-direction: column;

		.c-buttongroup {
			margin-top: 36px;
			display: flex;
			padding: 0;

			@media (max-width: 360px) {
				button {
					font-size: 16px;
				}
			}
		}
	}
}
