.page-myproducts {
	.c-container-header {
		h2 {
			font-size: 32px;
			margin-top: 0;
		}
	}
	.empty-state {
		text-align: center;

		img {
			margin-top: -30px;
		}

		p {
			font-size: 18px;
			margin-top: 0;
		}
	}
}
