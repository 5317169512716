.alert-box {
	position: relative;
	padding: 8px 0;
	border: 1px solid transparent;
	border-radius: 0.25rem;
	color: white;
	text-align: center;
	width: 100%;

	&--error {
		background-color: #ff3300;
		border-color: #ff3300;
	}

	&--success {
		background-color: #4bb543;
		border-color: #4bb543;
	}

	&--info {
		background-color: #009aff;
		border-color: #009aff;
	}
}
