.page-redeem {
	form {
		display: flex;
		flex-direction: column;

		.c-buttongroup {
			padding: 0;
			margin-top: 36px;
		}
	}

	&--product-info {
		display: flex;
		justify-content: center;
		flex-direction: column;
		margin-top: 80px;
		text-align: center;

		img {
			height: 100px;
			width: 100px;
			margin: auto;
		}

		h1 {
			color: var(--color-purple);
		}

		h2 {
			display: flex;
			margin: 0;
			font-size: 20px;
			font-weight: normal;
			margin: auto;

			p {
				margin: 0 5px;
			}
		}

		p {
			margin-bottom: 36px;
		}
	}
}
