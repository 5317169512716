.c-row {
	padding: 15px;

	&--no-padding {
		padding: 0;
	}

	&--hr {
		border-top: 1px solid grey;
		border-bottom: 1px solid grey;
	}
}
