.page-add-card {
	h2 {
		font-weight: normal;
		font-size: 20px;
	}

	&--form {
		display: flex;
		justify-content: space-between;
		margin-top: 16px;
		margin-bottom: 32px;

		.c-label {
			width: 100%;

			&:first-child {
				margin-right: 8px;
			}
		}
	}
}
