.c-ordersummary {
	border-top: rgba(0, 0, 0, 1);
	position: relative;
	h3 {
		font-size: 20px;
		font-weight: 300;
	}

	&--expandbutton {
		position: absolute;
		top: 0;
		right: 5px;
		text-decoration: underline;
		color: var(--color-purple);
	}

	&--ordertable {
		width: 100%;
		margin-bottom: 10px;

		tbody {
			display: flex;
			max-height: 60vh;
			overflow-y: scroll;
			flex-flow: wrap;

			tr {
				width: 100%;
				flex-grow: 1;
				display: flex;

				/*td {
					flex-grow: 1;
				}
				*/
			}

			tr:last-child td {
				background-image: linear-gradient(to right, black 10%, rgba(255, 255, 255, 0) 0%);
				background-position: bottom;
				background-size: 7px 1px;
				background-repeat: repeat-x;
			}
		}
	}

	&--removeline {
		flex-grow: 0 !important;
	}

	&--orderline {
		td {
			font-size: 16px;
			background-image: linear-gradient(to right, black 10%, rgba(255, 255, 255, 0) 0%);
			background-position: top;
			background-size: 7px 1px;
			background-repeat: repeat-x;
			padding: 16px 0 8px;

			button {
				margin-left: 22px;
			}
		}

		&:first-of-type td {
			background: none;
		}
	}

	&--coupon {
		font-style: italic;
		td {
			padding: 0 0 8px;
		}

		&--orderlineamount {
			padding-right: 40px !important;
		}
	}

	&--addon {
		td {
			padding-top: 0;
			padding-bottom: 16px;
		}

		&--orderlineamount {
			padding-right: 40px;
		}
	}

	&--sum {
		td {
			font-size: 20px;
			font-weight: 800;
			padding: 10px 0;

			p {
				margin: 0;
				display: inline-block;
			}
		}
	}

	&--name {
		flex-grow: 1;
	}
}
