.page-payment {
	h2 {
		color: var(--color-purple);
		font-size: 20px;
		font-weight: normal;
	}

	.c-errormessage {
		background-color: var(--color-red);
		color: white;
		padding: 15px;
		margin: 15px 0;
	}

	.c-confirmationmessage {
		background-color: white;
		padding: 15px;
		margin: 15px 0;
	}
}

.c-paymentform {
	margin-top: 20px;

	h3 {
		color: var(--color-purple);
	}

	&--exp-cvc {
		display: flex;
		justify-content: space-between;
		margin-top: 16px;

		.c-label {
			&:first-child {
				margin-right: 8px;
			}

			&:last-child {
				margin-bottom: 16px;
			}
		}
	}
}
