.c-box {
	border: 1px solid black;
	min-height: 25vh;
	text-align: center;
	margin: 20px 10px;
	display: flex;

	h2 {
		color: black;
		margin: auto;
	}
}
