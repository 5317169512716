.c-arrow {
	stroke: #0095ff;
	width: 30px;
	height: 10px;
	margin-left: 8px;
}

.c-arrow--link {
	color: #0095ff;
	text-decoration: none;

	&:hover svg {
		animation: easeOutBounce 0.5s 0s forwards;
	}
}

// Animation
@keyframes easeOutBounce {
	0% {
		transform: translate(0px);
		-webkit-animation-timing-function: ease-in;
	}
	33% {
		transform: translate(2px);
		-webkit-animation-timing-function: ease-in;
	}
	50% {
		transform: translate(4px);
		-webkit-animation-timing-function: ease-in;
	}
	66% {
		transform: translate(9px);
		-webkit-animation-timing-function: ease-in;
	}
	82% {
		transform: translate(8px);
		-webkit-animation-timing-function: ease-in;
	}
	92% {
		transform: translate(4px);
		-webkit-animation-timing-function: ease-in;
	}
	97% {
		transform: translate(8px);
		-webkit-animation-timing-function: ease-in;
	}
	100% {
		transform: translate(10px);
		-webkit-animation-timing-function: ease-in;
	}
}
