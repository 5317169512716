.c-navigation {
	border-top: 1px solid #ddd;
	overflow: hidden;
	box-shadow: 0 0 1em rgba(#000, 0.05);
	z-index: 1;
	position: relative;
	background-color: rgba(255, 255, 255, 0.4);
	padding-bottom: env(safe-area-inset-bottom);

	&.animate-expander {
		&-leave {
			height: 90px;
			transition: all 0.35s;

			&-active {
				height: 0;
			}
		}

		&-enter {
			height: 0;
			transition: all 0.35s;

			&-active {
				height: 90px;
			}
		}
	}

	&--list {
		display: flex;
		list-style: none;
		margin: 0.3em 0;
		padding: 0;
		width: 100%;
		height: 100%;
		justify-content: space-around;
		align-items: baseline;

		&-element {
			flex-grow: 1;
			text-align: center;
			align-self: baseline;
			max-width: 33%;

			&:first-child .c-icon {
				border: 1px solid;
			}

			.touchable {
				transform: scale(1.0001);

				&--touching {
					transform: scale(0.975);
				}
			}

			a {
				display: block;
				height: 100%;
				text-decoration: none;
				padding: 0.35em 0 0;
				color: #515151;

				.c-icon {
					display: block;
					border: 1px solid transparent;
					width: 20px;
					height: 20px;
					margin: 0 auto;
					padding: 3px;
					color: #515151;
					border-radius: 50%;
				}

				&.active {
					color: var(--color-purple);
					.c-icon {
						background-color: var(--color-purple);
						color: white;
					}
				}

				span {
					font-size: 0.9em;
				}
			}
		}
	}
}
