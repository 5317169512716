.page-cards {
	h3 {
		font-weight: normal;
		font-size: 20px;
		margin-bottom: 16px;
	}

	.c-card {
		margin-bottom: 16px;
		> div {
			display: flex;

			.c-icon {
				width: 24px;
				height: 24px;
			}

			p {
				margin: 3px 0 0 16px;
				font-size: 18px;
				font-weight: 600;
			}
		}

		p {
			margin-left: 40px;
		}

		&--active-container {
			position: absolute;
			right: 45px;
		}
	}

	.card-loading {
		height: 98px;
		width: 100%;
		border: 1px solid #dbe3e8;
		border-radius: 8px;
		@extend .animated-background;
	}
}

.animated-background {
	animation-duration: 1.25s;
	animation-fill-mode: forwards;
	animation-iteration-count: infinite;
	animation-name: placeHolderShimmer;
	animation-timing-function: linear;
	background: #fff;
	background: linear-gradient(to right, #fff 8%, #f6f6f6 18%, #fff 33%);
	background-size: 800px 104px;
	height: 98px;
	position: relative;
}

// Animation
@keyframes placeHolderShimmer {
	0% {
		background-position: -468px 0;
	}
	100% {
		background-position: 468px 0;
	}
}
