.c-switch {
	position: relative;
	display: inline-block;
	width: 57px;
	height: 31px;

	input {
		display: none;
	}

	.slider {
		position: absolute;
		cursor: pointer;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background-color: #ccc;
		-webkit-transition: 0.4s;
		transition: 0.4s;

		&:before {
			position: absolute;
			content: "";
			height: 25px;
			width: 25px;
			left: 3px;
			bottom: 3px;
			background-color: white;
			-webkit-transition: 0.4s;
			transition: 0.4s;
		}

		&.round {
			border-radius: 34px;
			&:before {
				border-radius: 50%;
			}
		}
	}

	input:checked + .slider {
		background-color: #2196f3;
	}

	input:focus + .slider {
		box-shadow: 0 0 1px #2196f3;
	}

	input:checked + .slider:before {
		transform: translateX(26px);
	}

	&--label {
		font-size: 16px;
		margin-right: 20px;
	}
}

.switch-wrapper {
	display: flex;
	align-items: center;
}
