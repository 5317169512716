.page-profile {
	.container {
		&:first-child {
			padding-bottom: 0;
		}
	}
	.user-header {
		display: flex;
		align-items: center;
		.c-icon {
			color: var(--color-purple);
			height: 40px;
			width: 40px;
			margin-right: 15px;
		}
	}

	button {
		margin-top: 0;
	}
}

.c-user-list {
	overflow: hidden;
	li {
		padding: 5px 0;
		h5 {
			color: #767676;
			font-weight: 300;
			padding-bottom: 0;
			margin-bottom: 0;
		}

		p {
			margin-top: 5px;
		}
	}
}
