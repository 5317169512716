.page-buy {
	ul {
		display: block;
		margin-top: 30px;
		li {
			border: 2px solid rgb(219, 227, 232);
			border-radius: 8px;
			margin-bottom: 16px;
			padding: 16px;
			a {
				display: block;
			}

			h3 {
				padding: 23px 0;
				font-size: 28px;
			}

			p {
				clear: both;
				padding: 5px 0;
				font-size: 16px;
			}
		}
	}

	header {
		padding: 0;
		h2 {
			color: var(--color-purple);
		}
	}

	.c-logosymbol {
		background-color: var(--color-blue);
		color: white;
		border-radius: 100%;
		border: 2px solid var(--color-blue);
		padding: 0.2em;
		width: 65px;
		height: 65px;
		margin-right: 20px;
		float: left;

		&--inverse {
			color: var(--color-blue);
			background-color: white;
		}
	}

	&--wide-link {
		width: 100%;
	}
}
