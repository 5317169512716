.container {
	padding: 15px;
	box-sizing: border-box;

	&--fill-height {
		min-height: 100%;
	}

	&--blue {
		background-color: var(--color-blue);
		color: var(--color-dark-gray);
	}

	&--gray {
		background-color: var(--color-light-gray);
	}

	&--center {
		text-align: center;
	}

	&--nopadding {
		padding: 0;
	}
}
