.page-transfer {
	.redeemcode {
		text-align: center;
	}

	&--userName {
		margin-bottom: 0;
	}

	&--description {
		p {
			&:nth-child(1),
			&:nth-child(2) {
				margin-bottom: 24px;
			}
		}
	}

	button {
		margin-top: 16px;
	}
}
