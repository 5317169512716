.page-receipt {
	text-align: center;
	padding-top: 0;
	.container {
		padding-top: 0;
	}
	.header {
		position: relative;
		top: 0;
		z-index: 1000;
		animation: scale-in-ver-center 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.4s both;
		opacity: 0;

		h2 {
			color: var(--color-purple);
			font-size: 60px;
			margin: 0;
		}
		p {
			font-size: 20px;
		}
	}

	.videocontainer {
		overflow: hidden;
		margin-top: -60px;
		z-index: 0;
		video {
			position: relative;
			margin-top: -10px;
		}
	}

	.c-alertbox {
		border: 1px solid var(--color-purple);
		text-align: center;
		padding: 20px;
		margin-top: -200px;
		z-index: 1000;
		position: relative;
		animation: scale-in-ver-center 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.8s both;
		opacity: 0;
		h3 {
			font-size: 30px;
			margin-top: 0;
		}
	}
}

@-webkit-keyframes scale-in-ver-center {
	0% {
		-webkit-transform: scaleY(0);
		transform: scaleY(0);
		opacity: 1;
	}
	100% {
		-webkit-transform: scaleY(1);
		transform: scaleY(1);
		opacity: 1;
	}
}
@keyframes scale-in-ver-center {
	0% {
		-webkit-transform: scaleY(0);
		transform: scaleY(0);
		opacity: 1;
	}
	100% {
		-webkit-transform: scaleY(1);
		transform: scaleY(1);
		opacity: 1;
	}
}
