body {
	line-height: 1.4;
	font-size: 1em;
	font-family: "Nunito";
}

h1 {
	line-height: 1.1;
	font-size: 50/16 + em;
}

h2 {
	line-height: 1.1;
	font-size: 32/16 + em;
}

h3 {
	line-height: 1.1;
	//color: var(--color-purple);
	font-size: 24/16 + em;
}

h4 {
	line-height: 1.1;
	font-size: 18/16 + em;
}

h5 {
	line-height: 1.1;
	font-size: 1em;
}

h6 {
	line-height: 1.1;
	opacity: 0.5;
	font-size: 0.85em;
}

p,
.p2,
h1,
h2,
h3,
h4,
h5,
h6 {
	margin: 0.5em 0;
	//margin-right: 1em;

	&--first {
		margin-top: 0;
	}
	&--last {
		margin-bottom: 0;
	}
}

.p2 {
	font-size: 1.2em;
	line-height: 1.3;
}

.fluff-top-big {
	margin-top: 2em;
}
.fluff-bottom-big {
	margin-bottom: 2em;
}

.fluff-top {
	margin-top: 1.25em;
}
.fluff-bottom {
	margin-bottom: 1.25em;
}

.fluff-top-small {
	margin-top: 0.75em;
}
.fluff-bottom-small {
	margin-bottom: 0.75em;
}
