.c-input,
.StripeElement {
	padding: 22px 12px 12px;
	color: black;
	font-size: 18px;
	background: transparent;
	outline: none;
	border-style: none;
	border-radius: 4px 4px 0 0;
	border: 1px solid rgb(219, 227, 232);
	border-bottom: 1px solid #767676;

	&::placeholder {
		color: rgba(192, 192, 192, 0.8);
		font-size: 20px;
	}

	&:focus {
		border-bottom: 3px solid #00ccde;
		box-shadow: none;
		// This is to stop it from pushing stuff under it down
		margin-bottom: -2px;
	}

	&--focus {
		border-bottom: 3px solid #00ccde;
		box-shadow: none;
		// This is to stop it from pushing stuff under it down
		margin-bottom: -2px;
	}

	.filled {
		border-bottom: 3px solid #767676;
		// This is to stop it from pushing stuff under it down
		margin-bottom: -2px;
	}

	&--invalid {
		border-color: #ff3300 !important;
	}
}

.c-label {
	font-size: 16px;
	margin-bottom: 5px;
	color: black;
	width: 100%;
}
