$easeOutQuart: cubic-bezier(0.165, 0.84, 0.44, 1);

.page {
	display: flex;
	flex-wrap: nowrap;
	flex: 1;
	flex-direction: column;
	box-shadow: 0 0 2em rgba(#000, 0.05);
	background-color: white;
	width: 100vw;
	bottom: 0;

	&--closebutton {
		align-self: flex-end;
		margin: 20px 20px 0;
	}

	&--blue {
		background-color: var(--color-blue);
	}

	&--loading {
		position: relative;
	}

	&--standalone {
		height: 100%;
	}

	&--popup {
		box-shadow: 0 0 2em rgba(#000, 0.05);
	}

	&__fixed-view {
		flex: 0;
	}

	&__scroll-view {
		flex: 1;
		overflow: scroll;
		-webkit-overflow-scrolling: touch;
		box-sizing: border-box;
		scroll-behavior: smooth;

		.animate-bottom-enter &,
		.animate-bottom-exit &,
		.animate-top-enter &,
		.animate-top-exit &,
		.animate-left-enter &,
		.animate-left-exit &,
		.animate-right-enter &,
		.animate-right-exit & {
			overflow: hidden;
		}
	}

	&.animate-bottom {
		&-enter {
			position: absolute;
			transform: translateY(100vh);
			left: 0;
			top: 0;
			z-index: 2;
			overflow: hidden;

			&-active {
				transform: translateY(0);
				transition: transform 0.5s $easeOutQuart;
			}
		}

		&-exit {
			position: absolute;
			transform: translateY(0);
			left: 0;
			top: 0;
			z-index: 2;
			overflow: hidden;

			&-active {
				transform: translateY(100vh);
				transition: transform 0.5s $easeOutQuart;
			}
		}
	}

	&.animate-top {
		&-enter {
			position: absolute;
			transform: translateY(-100vh);
			left: 0;
			top: 0;
			z-index: 100;
			overflow: hidden;

			&-active {
				transform: translateY(0);
				transition: transform 0.5s ease-in-out;
			}
		}

		&-exit {
			position: absolute;
			transform: translateY(0);
			left: 0;
			top: 0;
			z-index: 2;
			overflow: hidden;

			&-active {
				transform: translateY(100vh);
				transition: transform 0.5s ease-in-out;
			}
		}
	}

	&.animate-right {
		&-enter {
			position: absolute;
			top: 0;
			left: 0;
			transform: translateX(100vw);
			z-index: 1;
			overflow: hidden;

			&-active {
				transform: translateX(0);
				transition: transform 0.5s $easeOutQuart;
			}
		}

		&-exit {
			position: absolute;
			transform: translateX(0);
			overflow: hidden;
			left: 0;
			top: 0;
			z-index: 1;

			&-active {
				transform: translateX(100vw);
				transition: transform 0.5s $easeOutQuart;
			}
		}
	}

	&.animate-left {
		&-enter {
			position: absolute;
			transform: translateX(-100vw);
			z-index: 1;
			overflow: hidden;
			top: 0;
			left: 0;

			&-active {
				transform: translateX(0);
				transition: transform 0.5s $easeOutQuart;
			}
		}

		&-exit {
			position: absolute;
			top: 0;
			overflow: hidden;
			left: 0;
			transform: translateX(0);

			&-active {
				transform: translateX(100vw);
				transition: transform 0.5s $easeOutQuart;
			}
		}
	}

	// not needed?
	&.animate-none {
		&-enter {
			display: block;
		}

		&-exit {
			display: none;
		}
	}
}

.overlay {
	background-color: rgba(255, 255, 255, 1);
	z-index: 999999;
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	display: flex;
	flex-direction: column;
	justify-content: center;
	.videocontainer {
		//border: 1px solid red;
		text-align: center;
		video {
			height: 100px;
			width: 100px;
			margin: 0 auto 20px;
			//border: 1px solid blue;
			display: block;
		}
	}
}
