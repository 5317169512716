.app-wrapper {
	overflow: hidden;
	display: flex;
	width: 100vw;
	height: 100%;
	flex-direction: column;
	justify-content: flex-end;
	background-color: #fff;

	&__top {
		overflow: hidden;
		flex: 1;
		display: flex;
		position: relative;
		margin-top: env(safe-area-inset-top);
	}

	&__bottom {
		flex: 0;
		position: relative;
	}
}
