.page-terms {
	h2 {
		color: var(--color-purple);
		font-size: 20px;
		font-weight: normal;
	}

	h3 {
		font-size: 20px;
		font-weight: normal;
		margin: 0;
	}

	form {
		display: flex;
		flex-direction: column;
		margin: 16px 0 32px;

		input {
			margin-bottom: 16px;

			&:focus {
				margin-bottom: 14px;
			}
		}

		> div {
			display: flex;
			justify-content: space-between;

			> div {
				display: flex;
				flex-direction: column;

				.postCode {
					width: 33%;
				}
			}
		}

		.sub-desc {
			font-size: 14px;
		}
	}

	ul {
		display: flex;
		flex-direction: column;
		padding: 0;
		list-style: none;
		margin-top: 24px;

		li {
			display: inline-flex;
			margin-bottom: 32px;

			svg {
				align-self: center;
			}

			p {
				margin: 0 0 0 16px;
				max-width: 320px;
			}
		}
	}

	&--contract-link {
		margin-bottom: 32px;
	}

	&--checkbox {
		padding-bottom: 32px;
	}

	&--userName {
		color: var(--color-purple);
	}
}
