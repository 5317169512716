.page-subscriptionduration {
	h2 {
		font-size: 20px;
		font-weight: normal;
	}
	p {
		// Because HeaderContainer has a padding-bottom 15px
		margin-bottom: 9px;
	}
	.c-select-list {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		width: 100%;
		li {
			border: 2px solid #dbe3e8;
			padding: 15px;
			margin: 0;
			border-radius: 8px;
			margin-bottom: 15px;
			width: 38%;
			a {
				display: block;
			}

			.c-icon {
				width: 24px;
				height: auto;
				color: white;
				float: right;
				margin-top: 8px;
			}
		}
	}
}
