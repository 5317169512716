.page-productdetails {
	.c-container-header {
		margin: 0;
	}

	.container--gray {
		min-height: 45vh;
		text-align: left;

		&--next-payment {
			.c-card {
				margin: 16px 0;
				> div {
					display: flex;
					align-items: baseline;

					p {
						margin: 0 0 8px;
						font-size: 18px;
					}

					.c-icon {
						margin-right: 16px;
						width: 24px;
						height: 24px;
					}
				}
			}

			button {
				width: 191px;
			}
		}

		h3 {
			margin: 48px 0 8px;
		}

		h5 {
			font-weight: 600;

			&:nth-child(4) {
				margin-top: 27px;
			}
		}
	}

	.container {
		padding: 32px 15px;

		&:last-child {
			padding: 8px 15px;
		}
	}

	.c-button {
		margin-bottom: 16px;
	}

	.qrcode {
		text-align: center;
		margin: 0;
		svg {
			width: 60%;
			margin: 0 auto;
		}
	}

	&--validTo {
		padding: 8px;
		text-align: center;
		background-color: #b2f667;
		border-radius: 4px;
		margin-bottom: 16px;

		p {
			font-weight: 600;
		}
	}
}

.c-productdetails {
	text-align: center;

	h3 {
		margin: 16px;
		font-size: 20px;
		color: var(--color-purple);
	}

	ul {
		margin: 16px 0;
	}

	&--userName {
		margin-bottom: 16px;

		p {
			margin: 0;
		}
	}

	&--status {
		background-color: var(--color-light-gray);
		text-align: center;
		padding: 0.5em;
		margin: 0 0 16px 0;
		border-radius: 2px;
	}

	&--transfer-button {
		margin-top: 32px;
	}
}

.animate {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 80%;

	object {
		width: 100%;
		height: auto;
		margin-left: 35px;
	}
}
