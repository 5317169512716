.c-buttongroup {
	padding: 15px;
	&--align-bottom {
		bottom: 0;
		position: absolute;
		left: 0;
		right: 0;
		padding-bottom: calc(15px + env(safe-area-inset-bottom));
	}
	&--align-top {
		top: 10px;
		position: absolute;
		left: 10px;
		right: 10px;
	}
	&--bg-grey {
		background-color: var(--color-light-gray);
		box-shadow: 0 0 1em rgba(#000, 0.1);
		border-top: 1px solid #eee;
	}

	.c-button--full {
		margin-bottom: 15px;
		&:last-child {
			margin-bottom: 0;
		}
	}
}
