.page-quantity {
	h2 {
		font-size: 20px;
		font-weight: normal;
	}
}

.c-quantity-selector {
	width: 100%;
	margin: 15px 0;
	&--row {
		display: flex;
		//border-bottom: 1px dotted black;
		justify-content: space-between;
		padding: 15px 0;
		background-image: linear-gradient(to right, black 10%, rgba(255, 255, 255, 0) 0%);
		background-position: bottom;
		background-size: 7px 1px;
		background-repeat: repeat-x;
	}
	&--priceclass {
		width: 100px;
		h3 {
			margin: 0;
			font-size: 16px;
		}
		p {
			margin: 0;
			font-size: 14px;
		}
	}

	&--quantity {
		margin: 0;
	}
}

.c-quantitypicker {
	//border: 1px solid red;
	display: flex;
	align-items: center;
	width: 120px;
	background-color: var(--color-light-gray);
	border-radius: 40px;

	&--selected {
		background-color: var(--color-cyan);
	}

	button {
		//border: 1px solid red;
		height: 40px;
		width: 40px;
		background-color: white;
		border: 2px solid #dbe3e8;
		color: black;
		border-radius: 100%;
		line-height: 1em;
		font-size: 25px;
		font-weight: 600;

		&:active {
			transform: scale(0.97);
		}
	}
	span {
		width: 40px;
		//border: 1px solid;
		text-align: center;
		font-size: 28px;
		padding: 0;
	}
}
