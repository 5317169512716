.page-coupons {
	h2 {
		font-size: 28px;
		font-weight: 600;
	}

	&--coupons {
		margin: 24px 0;

		h4 {
			font-size: 16px;
			margin: 0;
			font-weight: normal;
		}

		h3 {
			font-size: 18px;
			font-weight: 600;
		}
	}

	h4 {
		font-weight: normal;
	}
}
