.page-subscription {
	h2 {
		font-size: 20px;
		font-weight: normal;
	}

	.c-subscription-group {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin: 16px 0 32px;

		&--container {
			display: flex;
			align-items: center;

			svg {
				width: 24px;
			}
		}

		.c-icon {
			width: auto;
			height: auto;
		}

		&--description {
			margin-left: 24px;

			p {
				margin: 0;
				font-size: 16px;

				&:first-child {
					font-size: 20px;
					font-weight: 600;
				}
			}
		}

		.c-icon--chevron-right {
			margin-left: auto;
		}
	}

	.link {
		display: block;
	}
}
