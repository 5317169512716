.c-loader {
	display: inline-block;
	position: relative;
	width: 64px;
	height: 10px;
}
.c-loader div {
	position: absolute;
	width: 11px;
	height: 11px;
	border-radius: 50%;
	background: #fff;
	animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.c-loader div:nth-child(1) {
	left: 6px;
	animation: loader-1 0.6s infinite;
}
.c-loader div:nth-child(2) {
	left: 6px;
	animation: loader-2 0.6s infinite;
}
.c-loader div:nth-child(3) {
	left: 26px;
	animation: loader-2 0.6s infinite;
}
.c-loader div:nth-child(4) {
	left: 45px;
	animation: loader-3 0.6s infinite;
}
@keyframes loader-1 {
	0% {
		transform: scale(0);
	}
	100% {
		transform: scale(1);
	}
}
@keyframes loader-3 {
	0% {
		transform: scale(1);
	}
	100% {
		transform: scale(0);
	}
}
@keyframes loader-2 {
	0% {
		transform: translate(0, 0);
	}
	100% {
		transform: translate(19px, 0);
	}
}
